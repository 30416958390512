<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Preview</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="data">
            <PreviewTemplate :template="form.template" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hide">
            {{ $t('generic-str.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import PreviewTemplate from '@/components/rebranding/whatsappj/PreviewTemplate.vue';

export default {
  components: {
    PreviewTemplate,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: { template: {} },
    };
  },
  mounted() {},
  created() {
    this.$root.$on('updated.previewwhats', this.insertTemplate);
  },
  methods: {
    insertTemplate(template) {
      this.form.template = template;
      this.form.template.type = 'DEFAULT';
      if (this.form.template.buttons) {
        if (this.form.template.buttons.type === 'CATALOG') {
          this.form.template.button_index = 0;
          this.form.template.type = 'CATALOG';
          this.form.template.button_parameter = [];
        }
        if (this.form.template.buttons.type === 'COPY_CODE') {
          this.form.template.button_index = 0;
          this.form.template.type = 'CUPOM';
          this.form.template.button_parameter = '';
        }
        if (this.form.template.buttons.type === 'MPM') {
          this.form.template.type = 'MPM';
        }
        if (this.form.template.limited !== undefined) {
          this.form.template.type = 'OFFER';
          this.form.template.expiration_time_ms = 1;
        }
      } else {
        this.form.template.buttons = {};
      }

      if (this.form.template.carousel) {
        this.form.template.type = 'CAROUSEL';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.preview-action-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}
.preview-action-buttons .div-reply {
  z-index: 1024;
  align-items: center;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 5px;
}
</style>
